import {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {CheckInQueries} from './actions/CheckIn.queries';
import {Loader} from '../../components';
import {CheckInRender} from './ChekIn.render';

export const CheckInLoad = () => {
	const [limit, setLimit] = useState(100);
	const [skip, setSkip] = useState(0);
	const [allCheckIns, setAllCheckIns] = useState([]);
	const [filteredCheckIns, setFilteredCheckIns] = useState([]);
	const { loading, error, data, refetch } = useQuery(
		CheckInQueries('checkInUserAnswerFindMany'),
		{
			variables: { limit: 500, skip: 0, sort: '_ID_DESC' },
			fetchPolicy: 'network-only',
			onCompleted: (data) => {
				if (data?.CheckInUserAnswerFindMany) {
					setFilteredCheckIns(data.CheckInUserAnswerFindMany);
					setAllCheckIns(data.CheckInUserAnswerFindMany || []);
				}
			},
		}
	);

	useEffect(() => {
		refetch().then(()=>{});
	}, [skip, limit, refetch]);

	const totalPages = Math.ceil(filteredCheckIns.length / limit);

	const handleNextPage = () => {
		if (skip + limit < filteredCheckIns.length) setSkip(skip + limit);
	};

	const handlePreviousPage = () => {
		if (skip > 0) {
			setSkip(skip - limit);
		}
	};

	if (loading) return <Loader />;
	if (error) return <p>Error: {error.message}</p>;

	return (
		<CheckInRender
			checkIns={filteredCheckIns.slice(skip, skip + limit)}
			skip={skip}
			limit={limit}
			setLimit={setLimit}
			onNextPage={handleNextPage}
			onPreviousPage={handlePreviousPage}
			totalPages={totalPages}
			currentPage={Math.floor(skip / limit) + 1}
			allCheckIns={allCheckIns.length}
		/>
	);
};