import React,{useContext, useEffect} from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {Menu} from './components';
import {
	Answers, AdminProfile,
	Users, UserCreate, UserInfo,
	Lessons, LessonsCreate, LessonsEdit,
	LifeSkill, LifeSkillEdit, LifeSkillCreate,
	Themes, ThemesCreate, ThemesEdit,
	SkillSet, SkillSetCreate, SkillSetEdit,
	Courses, CoursesCreate, CoursesEdit,
	Mood, MoodCreate, MoodEdit,
	Avatar, AvatarCreate, AvatarEdit,
	Journals,
	CheckIn,
	Collectibles, CollectiblesCreate, CollectiblesEdit,
	SparkLibraries,
	Pin, PinCreate, PinEdit,
	District, DistrictDetails,
	Sticker, StickerCreate, StickerEdit,
	Badges, BadgesCreate, BadgesEdit,
	Schools, SchoolDetails
} from './pages';
import {
	Login,
	Questions
} from './containers';
import './css/index.js';
import { ApolloProvider } from '@apollo/client';
import {client} from './actions/apoloclient';
import {refreshToken} from './actions/restactions';
import AuthContext from './contexts/auth/AuthContext';
import {QuestionForm} from './components/forms/QuestionForm';
import {ResponseForm} from './components/forms/ResponseForm';
import {ClassForm} from './components/forms/ClassForm';
import {isTokenExpired} from './tools';

const AuthRoute = ({ component: Component, ...rest }) => {
	const authContext = useContext(AuthContext);
	const { auth } = authContext;
	return (
		<Route
			{...rest}
			render={props =>
				auth ? (
					<Component {...props} />
				) : (
					<Redirect to='/login' />
				)
			}
		/>
	);
};

function Routes() {
	const authContext = useContext(AuthContext);
	const { auth, authUser, logoutUser } = authContext;
	const history = useHistory();

	useEffect(() => {
		const checkToken = async () => {
			const token = localStorage.getItem('accessToken');
			if (!token) {
				return history.push('/login');
			}
			if (isTokenExpired()) {
				try {
					await refreshToken();
					authUser(token);
				} catch (error) {
					logoutUser();
					history.push('/login');
				}
			}
		};
		checkToken().then(() => console.log('token done'));
	}, [auth, history, authUser, logoutUser]);

	return (
		<ApolloProvider client={client()}>
			<Switch>
				<Route exact path='/login'>
					<div className={'flex flex-1'}></div>
					<Login />
				</Route>
				<Route path='/'>
					<div className='flex'>
						<div className='w-300px mw-300px'>
							<Menu />
						</div>
						<div className='main-color-background w-80vw h-100vw pr-2'>
							<div className='main-color-background m-2 p-2 br sh-16-black10'>
								<Switch>
									<AuthRoute exact path='/home' component={Users}  />
									<AuthRoute exact path='/admin-profile' component={AdminProfile}  />
									<AuthRoute exact path='/questions' component={Questions} />
									<AuthRoute exact path='/question/:id' component={QuestionForm} />
									<AuthRoute exact path='/answers' component={Answers} />
									<AuthRoute exact path='/response/:id' component={ResponseForm} />
									<AuthRoute exact path='/lessons' component={Lessons} />
									<AuthRoute exact path='/lessons/create' component={LessonsCreate} />
									<AuthRoute exact path='/lessons/:id' component={LessonsEdit} />
									<AuthRoute exact path='/lessons/:id/:tab' component={LessonsEdit} />
									<AuthRoute exact path='/courses/create' component={CoursesCreate} />
									<AuthRoute exact path='/courses/:id' component={CoursesEdit} />
									<AuthRoute exact path='/courses/:id/:tab' component={CoursesEdit} />
									<AuthRoute exact path='/courses' component={Courses} />
									<AuthRoute exact path='/class/:id' component={ClassForm} />
									<AuthRoute exact path='/users' component={Users} />
									<AuthRoute exact path='/users/create' component={UserCreate} />
									<AuthRoute exact path='/users/:tab' component={Users} />
									<AuthRoute exact path='/usersInfo/:id' component={UserInfo} />
									<AuthRoute exact path='/lifeSkills' component={LifeSkill} />
									<AuthRoute exact path='/lifeSkills/create' component={LifeSkillCreate} />
									<AuthRoute exact path='/lifeSkills/:id' component={LifeSkillEdit} />
									<AuthRoute exact path='/themes' component={Themes} />
									<AuthRoute exact path='/themes/create' component={ThemesCreate} />
									<AuthRoute exact path='/themes/:id' component={ThemesEdit} />
									<AuthRoute exact path='/skillSet' component={SkillSet} />
									<AuthRoute exact path='/skillSet/create' component={SkillSetCreate} />
									<AuthRoute exact path='/skillSet/:id' component={SkillSetEdit} />
									<AuthRoute exact path='/mood' component={Mood} />
									<AuthRoute exact path='/mood/create' component={MoodCreate} />
									<AuthRoute exact path='/mood/:id' component={MoodEdit} />
									<AuthRoute exact path='/avatar' component={Avatar} />
									<AuthRoute exact path='/avatar/create' component={AvatarCreate} />
									<AuthRoute exact path='/avatar/:id' component={AvatarEdit} />
									<AuthRoute exact path='/journals' component={Journals} />
									<AuthRoute exact path='/checkin' component={CheckIn} />
									<AuthRoute exact path='/collectibles' component={Collectibles} />
									<AuthRoute exact path={'/sparks-libraries'} component={SparkLibraries}/>
									<AuthRoute exact path='/collectibles/create' component={CollectiblesCreate} />
									<AuthRoute exact path='/collectibles/:id' component={CollectiblesEdit}/>
									<AuthRoute exact path='/pin' component={Pin}/>
									<AuthRoute exact path='/pin/create' component={PinCreate}/>
									<AuthRoute exact path='/pin/:id' component={PinEdit}/>
									<AuthRoute exact path='/districts' component={District}/>
									<AuthRoute exact path='/districts/:id/organization/:organization' component={DistrictDetails}/>
									<AuthRoute exact path='/stickers' component={Sticker}/>
									<AuthRoute exact path='/stickers/create' component={StickerCreate}/>
									<AuthRoute exact path='/stickers/:id' component={StickerEdit}/>
									<AuthRoute exact path='/badges' component={Badges}/>
									<AuthRoute exact path='/badges/create' component={BadgesCreate}/>
									<AuthRoute exact path='/badges/:id' component={BadgesEdit}/>
									<AuthRoute exact path='/schools' component={Schools}/>
									<AuthRoute exact path='/schools/:id' component={SchoolDetails}/>
								</Switch>
							</div>
						</div>
					</div>
				</Route>
			</Switch>
		</ApolloProvider>
	);
}

export default Routes;