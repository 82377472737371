import React from 'react';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import PropTypes from 'prop-types';

export const  SchoolFindManyByOrganization = ({children, district}) => {
	if (!district || district.trim() === '') {
		return <p className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark'}>There are no Schools in this Districts</p>;
	}

	const { loading, error, data } = useQuery(query('schoolFindMany'),{
		variables: {filter: { district }}
	});

	if (loading) return <p className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Loading...</p>;
	if (error) return <p className={'font-sans ml-3 mb-4 text-red-600 self-start'}>Error: {error.message}</p>;
	if (!data || !data.SchoolFindMany || data.SchoolFindMany.length === 0) return <p className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark'}>There is no Schools in this Districts</p>;

	return children(data.SchoolFindMany);
};

SchoolFindManyByOrganization.propTypes = {
	children: PropTypes.func.isRequired,
	district: PropTypes.string.isRequired
};