import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {CheckInQueries} from '../pages/CheckIn/actions/CheckIn.queries';

export const GroupFindOneById = ({_id}) => {
	if(!_id) return 'Group not found';

	const { data, loading, error, refetch } = useQuery(CheckInQueries('groupFindMany'),{
		variables: { limit: 1, skip: 0, filter: { _id }, sort: '_ID_DESC' },
		fetchPolicy: 'network-only',
	});
	if(loading ) return null;
	if( error || !data || !data.GroupFindMany[0]) return 'Group not found';

	return `${data.GroupFindMany[0].name}`;
};

GroupFindOneById.propTypes = {
	_id: PropTypes.string.isRequired,
};