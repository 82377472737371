import {LessonsEdit} from './LessonsEdit';
import {useHistory, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {useEffect, useState} from 'react';

export const LessonsEditLoad = () => {
	const { id, tab } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('classesAdminFindOne'), {
		variables: { id },
		fetchPolicy: 'network-only'
	});

	const [lesson, setLesson] = useState(undefined);

	useEffect(() => {
		if (data) {
			const lessonData = data.ClassesAdminFindOne;
			setLesson({
				...lessonData,
				questions: lessonData.questions || [],
				lifeSkill: lessonData.lifeSkill || [],
				skillSet: lessonData.skillSet || [],
				theme: lessonData.theme || [],
			});
		}
	}, [data]);

	if (loading) return <h2>Loading...</h2>;
	if (error) return <p>Error: {error.message}</p>;

	return (
		lesson ?<LessonsEdit id={id} history={history} lesson={lesson}/> :null
	);
};