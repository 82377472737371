import gql from 'graphql-tag';

const queries = {
	VideoTapsFindOne: gql`
		query VideoTapsFindOne($filter: FilterFindOnevideotapInput, $skip: Int, $sort: SortFindOnevideotapInput){
            VideoTapsFindOne( filter: $filter, skip: $skip, sort: $sort ){
                _id
                lesson
                start
                end
                sparkLibrary
                tag
                deletedAt
                createdAt
                updatedAt
			}
		}
	`,
	VideoTapsFindMany: gql`
		query VideoTapsFindMany($filter: FilterFindManyvideotapInput, $skip: Int, $limit: Int, $sort:SortFindManyvideotapInput) {
            VideoTapsFindMany(filter: $filter, skip: $skip, limit: $limit ,sort: $sort){
                _id
                lesson
                start
                end
                sparkLibrary
                tag
                deletedAt
                createdAt
                updatedAt
			}
		}
	`,
	CheckInQuestionFindMany: gql`
		query CheckInQuestionFindMany($filter: FilterFindManycheckinquestionInput, $skip: Int, $sort: SortFindManycheckinquestionInput){
           CheckInQuestionFindMany(filter: $filter, skip: $skip, sort: $sort){
               _id
               curriculum
               class
               question
               start
               deletedAt
               createdAt
               updatedAt
           }
		}
	`,
	CheckInQuestionFindOne: gql`
		query CheckInQuestionFindOne($filter: FilterFindOnecheckinquestionInput, $skip: Int, $sort: SortFindOnecheckinquestionInput){
            CheckInQuestionFindOne(filter: $filter, skip: $skip, sort: $sort){
                _id
                curriculum
                class
                question
                start
                deletedAt
                createdAt
                updatedAt
            }
		}
	`,
};

export const LessonQueries = ( value ) => {
	return queries[ value ];
};