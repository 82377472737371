import React from 'react';
import ThemeToggleButton from '../../components/ui/ToggleThemeButton';

function Home(){
	return (
		<div>
			<h2>Home</h2>
			<ThemeToggleButton/>+
			<div>
				<div className='flex justify-center'>
				</div>
			</div>
		</div>
	);
}

export default Home;