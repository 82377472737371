import gql from 'graphql-tag';

const queries = {
	checkInUserAnswerFindMany: gql`
		query CheckInUserAnswerFindMany($filter: FilterFindManycheckinuseranswerInput, $skip: Int, $limit: Int, $sort: SortFindManycheckinuseranswerInput){
			CheckInUserAnswerFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
				    _id
					question
					answer
					user
					group
					scale
					class
					deletedAt
					createdAt
					updatedAt
			}
		}
	`,
	groupFindMany: gql`
		query GroupFindMany($filter: FilterFindManygroupsInput, $skip: Int, $limit: Int,$sort: SortFindManygroupsInput){
			GroupFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
				_id
				manager
				name
				school
				grade
				code
				deleted
			}
		}
	`,
	checkInQuestionFindOne: gql`
		query CheckInQuestionFindOne($filter: FilterFindOnecheckinquestionInput, $skip: Int, $sort: SortFindOnecheckinquestionInput){
			CheckInQuestionFindOne(filter: $filter, skip: $skip, sort: $sort){
				_id
				curriculum
				class
				question
				start
				deletedAt
				createdAt
				updatedAt
			}
		}
	`
};

export const CheckInQueries = (value) => {
	return queries[ value ];
};