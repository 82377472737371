import {useQuery} from '@apollo/client';
import {DistrictQueries} from '../../District/actions/district.queries';
import {useState} from 'react';
import PropTypes from 'prop-types';
import Select from '../../../components/ui/Select';
import {HistoryLineChart} from '../../../components/charts';

const yesterday = d => new Date(d.setDate(d.getDate()-1)) ;
const lastMonth = d => new Date(d.setMonth(d.getMonth() - 1));
const lastYear = d => new Date(d.setFullYear(d.getFullYear() - 1));
const last2Year = d => new Date(d.setFullYear(d.getFullYear() - 1));

const StringDateToIsoStringDate = (dateString)=> new Date(dateString).toISOString();
export const userTypes = {
	teacher:'e4475192-3c69-11ee-be56-0242ac120002',
	student :'cae9c4f0-b804-11eb-87d9-ab38d9aa58aa'
};

export const UserHistory = ({userType,historyQuery})=>{
	const now = new Date();
	const then = new Date();
	const [dateField,setDateField] = useState( 'createdAt' );
	const [startDate,setStartDate] = useState( last2Year(then) );
	const [endDate,setEndDate] = useState(now);
	console.log({userType,typeId:userTypes[userType]});
	const { loading:historyLoading, error:historyError, data:historyData, } = useQuery(historyQuery, {
		variables: {
			type: userType ? userTypes[userType]:null,
			dateField,
			startDate:startDate.toISOString(),
			endDate:endDate.toISOString(),
		}
	});

	const handleOnchangeDateField =(value)=>setDateField(value);
	const handleChangeStartDate = (value)=>setStartDate(new Date(value));
	const handleChangeEndDate = (value)=>setEndDate(new Date(value));
	const history = historyData?.OrganizationUserHistoryFindMany;

	return (
		<div>
			<h1> History</h1>
			<div className={'flex flex-col gap-4 max-w-[1200px] text-white'}>
				<div className={'flex gap-8 '}>
					<Select
						className={'max-w-xs'}
						options={[
							{value:'createdAt', label:'Created'},
							{value:'lastLogin', label:'Last login'},
						]}
						onChange = {e=>handleOnchangeDateField(e.target.value)}
					/>
					<div className={'flex gap-1 my-3 '}>
						<label className={'h-1rem'}>From :</label>
						<input
							className={'border h-9 border-cloudyBlue-dark rounded px-1 text-cloudyBlue-dark bg-cloudyBlue-light'}
							type={'date'}
							min='2018-01/01'
							max={endDate.toISOString().split('T')[0]}
							defaultValue={then.toISOString().split('T')[0]}
							onChange={e=>handleChangeStartDate(e.target.value)}
						/>
					</div>
					<div className={'flex gap-1 my-3'}>
						<label className={'h-1rem'}>To :</label>
						<input
							className={'border h-10 border-cloudyBlue-dark rounded px-1 text-cloudyBlue-dark bg-cloudyBlue-light'}
							type={'date'}
							max={now.toISOString().split('T')[0]}
							defaultValue={now.toISOString().split('T')[0]}
							onChange={e=>handleChangeEndDate(e.target.value)}
						/>
					</div>

				</div>
			</div>
			<p className={'my-1'}> data length :{ history?.length }</p>
			{ historyData ?<HistoryLineChart historyData={history}/> :null}
		</div>
	);
};

UserHistory.prototype = {
	userType : PropTypes.oneOf(['student','teacher'])
};