import PropTypes from 'prop-types';
import {
	CheckInQuestionFindOne,
	ClassFindOneById,
	GroupFindOneById,
	QuestionFindOneByIDOnlyName,
	UserFindOneByIdOnlyName
} from '../../hoc';
import {SelectDropDown} from '../../components';
import {ArrowIndicatorIcon} from '../../icons';
import {
	StyledTableRow,
	UserCounterTitleTable,
	UserPageWrapperElements,
	UserTableMainWrapper
} from '../Users/Users.style';

export const CheckInRender = ({ checkIns, skip, limit, setLimit, onNextPage, onPreviousPage, allCheckIns }) => {
	return (
		<div className='w-full h-full m-2'>
			<div className='w-full flex justify-between items-center p-1 mb-3 gap-6'>
				<h1 className='font-sans text-white f-500 text-2xl'>Check-Ins</h1>
			</div>
			<div className='font-mono f-500 text-sm text-white mb-3'>
				<span><strong style={{ color: 'rgba(203, 60, 255, 1)' }}>{skip + 1} - {Math.min(skip + limit, checkIns.length) + skip}</strong></span>
				<span> of {allCheckIns}</span>
			</div>
			<UserPageWrapperElements>
				{checkIns.length > 0 ? (
					<UserTableMainWrapper className='mt rounded-lg p-2.5'>
						<thead>
							<tr>
								<th className='left font-mono main-color-background p-2 t-white'>Question</th>
								<th className='left font-mono main-color-background p-2 t-white'>Answer</th>
								<th className='left font-mono main-color-background p-2 t-white'>Student</th>
								<th className='left font-mono main-color-background p-2 t-white'>Group</th>
								<th className='left font-mono main-color-background p-2 t-white'>Scale</th>
								<th className='left font-mono main-color-background p-2 t-white'>Class</th>
							</tr>
						</thead>
						<tbody>
							{checkIns.map((checkIn) => (
								<StyledTableRow key={checkIn._id}>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											<CheckInQuestionFindOne _id={checkIn.question}/>
										</span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>{checkIn.answer || 'No answer set'}</span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'><UserFindOneByIdOnlyName id={checkIn?.user} /></span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											{checkIn.group ? (
												<GroupFindOneById _id={checkIn.group}/>
											) : 'Group not found' }
										</span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>{checkIn?.scale || 'No scale set'}</span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											<ClassFindOneById id={checkIn?.class}>
												{(classData) => (classData ? classData.title : 'Answer not set')}
											</ClassFindOneById>
										</span>
									</td>
								</StyledTableRow>
							))}
						</tbody>
					</UserTableMainWrapper>
				) : (
					<div className='flex justify-center items-center text-white font-mono text-lg p-4'>
						No Check-Ins Found.
					</div>
				)}
			</UserPageWrapperElements>
			<div className='flex justify-between items-center mb-6 mt-6 p-2.5 pr-4 pl-4'>
				<UserCounterTitleTable className='font-mono'>
					<span>{skip + 1} - {Math.min(skip + limit, checkIns.length) + skip}</span>
					<span> of {allCheckIns}</span>
				</UserCounterTitleTable>
				<SelectDropDown minValue={limit} onChange={setLimit} />
				<div className='flex items-center gap-1'>
					<div className='cursor-pointer rounded-lg' onClick={onPreviousPage}>
						<ArrowIndicatorIcon className='transform rotate-180' />
					</div>
					<div className='ml-4 cursor-pointer rounded-lg' onClick={onNextPage}>
						<ArrowIndicatorIcon />
					</div>
				</div>
			</div>
		</div>
	);
};

CheckInRender.propTypes = {
	checkIns: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			question: PropTypes.string,
			answer: PropTypes.string,
			user: PropTypes.string,
			group: PropTypes.string,
			scale: PropTypes.string,
			class: PropTypes.string,
			createdAt: PropTypes.string.isRequired,
			updatedAt: PropTypes.string.isRequired,
			deletedAt: PropTypes.string,
		})
	).isRequired,
	skip: PropTypes.number.isRequired,
	limit: PropTypes.number,
	setLimit: PropTypes.func.isRequired,
	onNextPage: PropTypes.func.isRequired,
	onPreviousPage: PropTypes.func.isRequired,
	totalPages: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	allCheckIns: PropTypes.number.isRequired,
};